import React from 'react';
import { ImageComponentProps } from 'react-photo-gallery';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image';

const styles = (theme: Theme) =>
  createStyles({
    galleryImage: {
      cursor: 'pointer'
    }
  });

const GalleryImage: React.SFC<GalleryImageProps> = ({ classes, margin, index, photo, onClick }) => (
  <div className={classes.galleryImage} style={{ margin, height: photo.height, width: photo.width, cursor: 'pointer' }} onClick={(ev) => onClick ? onClick(ev, { index, photo }) : {}}>
    <Img fluid={photo.fluid} />
  </div>
);

interface GalleryImageProps extends ImageComponentProps, WithStyles<typeof styles> {
}

export default withStyles(styles)(GalleryImage);